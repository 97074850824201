import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor() {}

  public isLogged(): boolean {
    const token = this.getToken();
    if (token) {
      return true;
    }
    return false;
  }

  public getToken(): string | null {
    const token = localStorage.getItem("token");
    if (token) {
      return token;
    }
    return null;
  }

  public removeToken(): void {
    localStorage.clear();
  }

  public saveToken(token: string): void {
    if (token) {
      localStorage.setItem("token", token);
    }
  }
}
