<header class="header">
  <div class="header__left">
    <a [routerLink]="[ROUTES['MY_POINTS']]">
      <img src="../assets/logo.png" class="header__left__logo">
    </a>
  </div>
  <div class="header__right">
    <a [routerLink]="[ROUTES['LOGIN']]" *ngIf="auth.isLogged()" (click)="logOut()"
      class="header__right__link -is-red-color">
      {{t._("log_out")}}
    </a>
  </div>
</header>
<div toastContainer></div>
<router-outlet></router-outlet>
