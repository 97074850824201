import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class TranslateOnFlyService {
  private language: string;
  private translations: { [key: string]: string };
  constructor() {
    this.language = this.getDefaultLanguage();
    this.translations = require(`../../../i18n/on-fly-translates/${this.language}.json`);
  }

  public _(textToTranslate: string, variables: { [word: string]: string } = null): string {
    let translate = textToTranslate ? this.translations[textToTranslate] || textToTranslate : "";
    if (variables && translate !== "") {
      translate = Object.keys(variables).reduce((acc, key) => acc.replace(new RegExp(`<%= ${key} %>`, "g"), variables[key]), translate);
    }
    return translate;
  }

  private getDefaultLanguage(): string {
    const browserLang = (navigator.language || "").split("-")[0].toLocaleLowerCase() || "";
    if (browserLang === "en" || browserLang === "de") return browserLang;
    return (environment.LANGUAGE && environment.LANGUAGE.toLocaleLowerCase()) || "en";
  }
}
