import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ToastContainerDirective, ToastrService } from "ngx-toastr";

import { ROUTES } from "./constants";
import { AuthService } from "./providers/auth/auth.service";
import { TranslateOnFlyService } from "./services/translate-on-fly/translate-on-fly.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @ViewChild(ToastContainerDirective) public toastContainer: ToastContainerDirective;
  public ROUTES: { [key: string]: string } = ROUTES;

  constructor(public t: TranslateOnFlyService, public auth: AuthService, private router: Router, private toastrService: ToastrService) {}

  public ngOnInit(): void {
    if (this.auth.isLogged()) {
      this.router.navigate([ROUTES.MY_POINTS]);
    } else {
      this.router.navigate([ROUTES.LOGIN]);
    }
    this.toastrService.overlayContainer = this.toastContainer;
  }

  public logOut(): void {
    this.auth.removeToken();
  }
}
