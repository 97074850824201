import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./pages/login/login.component";
import { MyPointsComponent } from "./pages/my-points/my-points.component";
import { ROUTES } from "./constants";
import { AuthGuard } from "./guards/auth/auth.guard";

export const routes: Routes = [
  {
    path: ROUTES.LOGIN,
    component: LoginComponent,
  },
  {
    path: ROUTES.MY_POINTS,
    canActivate: [AuthGuard],
    component: MyPointsComponent,
  },
  {
    path: "**",
    redirectTo: ROUTES.MY_POINTS,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
