import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { SentryService } from "../../services/sentry/sentry.service";
import { environment } from "../../../environments/environment";
import { ENDPOINTS } from "../../constants";
import { IResponse, IPointsResult, IExchangePackageAvailableToUser } from "src/shared/api";
import { DeutscheBahnCardValidatorService } from "src/app/services/deutsche-bahn/deutsche-bahn-card-validator.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private sentry: SentryService, private http: HttpClient, private dbCardValidatorService: DeutscheBahnCardValidatorService) {}

  public async login(email: string, password: string): Promise<IResponse<string>> {
    this.sentry.breadcrumb("login", "http");
    if (!email || !password) {
      throw {
        error: { message: "You should include e-mail/username and password" },
      };
    }
    return this.http
      .post(`${environment.PROTOCOL}://${environment.API}/${ENDPOINTS.LOGIN}`, {
        email,
        password,
      })
      .toPromise<any>();
  }

  public async getPoints(): Promise<IResponse<IPointsResult>> {
    this.sentry.breadcrumb("getPoints", "http");
    return this.http.get(`${environment.PROTOCOL}://${environment.API}/${ENDPOINTS.POINTS}`).toPromise<any>();
  }

  public async redeemPoints(pack: IExchangePackageAvailableToUser): Promise<IResponse<IPointsResult>> {
    this.sentry.breadcrumb("redeemPoints", "http");
    return this.http.post(`${environment.PROTOCOL}://${environment.API}/${ENDPOINTS.REDEEM_POINTS}`, pack).toPromise<any>();
  }
}
