/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../atoms/button/button.component.ngfactory";
import * as i4 from "../../atoms/button/button.component";
import * as i5 from "./confirmation-modal.component";
var styles_ConfirmationModalComponent = [i0.styles];
var RenderType_ConfirmationModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationModalComponent, data: {} });
export { RenderType_ConfirmationModalComponent as RenderType_ConfirmationModalComponent };
function View_ConfirmationModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "confirmation-modal__content__header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_ConfirmationModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h6", [["class", "confirmation-modal__content__header__subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subtitle; _ck(_v, 1, 0, currVal_0); }); }
function View_ConfirmationModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "confirmation-modal__content__body__explanation"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.explanation; _ck(_v, 1, 0, currVal_0); }); }
export function View_ConfirmationModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "confirmation-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "confirmation-modal__veil"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "confirmation-modal__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "confirmation-modal__content__header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationModalComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationModalComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "confirmation-modal__content__body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationModalComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 8, "div", [["class", "confirmation-modal__content__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "app-button", [["class", "confirmation-modal__content__footer__button"]], null, [[null, "eventClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventClick" === en)) {
        var pd_0 = (_co.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i1.ɵdid(13, 49152, null, 0, i4.ButtonComponent, [], { color: [0, "color"] }, { eventClick: "eventClick" }), (_l()(), i1.ɵeld(14, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Abbrechen"])), (_l()(), i1.ɵeld(16, 0, null, null, 3, "app-button", [["class", "confirmation-modal__content__footer__button"]], null, [[null, "eventClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventClick" === en)) {
        var pd_0 = (_co.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i1.ɵdid(17, 49152, null, 0, i4.ButtonComponent, [], { color: [0, "color"] }, { eventClick: "eventClick" }), (_l()(), i1.ɵeld(18, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Best\u00E4tigen"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.subtitle; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.explanation; _ck(_v, 10, 0, currVal_2); var currVal_3 = "dark"; _ck(_v, 13, 0, currVal_3); var currVal_4 = "primary"; _ck(_v, 17, 0, currVal_4); }, null); }
export function View_ConfirmationModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirmation-modal", [], null, null, null, View_ConfirmationModalComponent_0, RenderType_ConfirmationModalComponent)), i1.ɵdid(1, 49152, null, 0, i5.ConfirmationModalComponent, [], null, null)], null, null); }
var ConfirmationModalComponentNgFactory = i1.ɵccf("app-confirmation-modal", i5.ConfirmationModalComponent, View_ConfirmationModalComponent_Host_0, { show: "show", title: "title", subtitle: "subtitle", explanation: "explanation" }, { isConfirmed: "isConfirmed" }, []);
export { ConfirmationModalComponentNgFactory as ConfirmationModalComponentNgFactory };
