<div class="confirmation-modal">
  <div class="confirmation-modal__veil"></div>
  <div class="confirmation-modal__content">
    <div class="confirmation-modal__content__header">
      <h2 class="confirmation-modal__content__header__title" *ngIf="title">
        {{ title }}
      </h2>
      <h6
        class="confirmation-modal__content__header__subtitle"
        *ngIf="subtitle"
      >
        {{ subtitle }}
      </h6>
    </div>
    <div class="confirmation-modal__content__body">
      <p
        class="confirmation-modal__content__body__explanation"
        *ngIf="explanation"
      >
        {{ explanation }}
      </p>
    </div>
    <div class="confirmation-modal__content__footer">
      <app-button
        class="confirmation-modal__content__footer__button"
        [color]="'dark'"
        (eventClick)="close(false)"
      >
        <p>Abbrechen</p>
      </app-button>
      <app-button
        class="confirmation-modal__content__footer__button"
        [color]="'primary'"
        (eventClick)="close(true)"
      >
        <p>Bestätigen</p>
      </app-button>
    </div>
  </div>
</div>
