import { ToastrService } from "ngx-toastr";
import { TranslateOnFlyService } from "../translate-on-fly/translate-on-fly.service";
import { SentryService } from "../sentry/sentry.service";
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
import * as i2 from "../translate-on-fly/translate-on-fly.service";
import * as i3 from "../sentry/sentry.service";
var ToastService = /** @class */ (function () {
    function ToastService(toastr, translateOnFly, sentry) {
        this.toastr = toastr;
        this.translateOnFly = translateOnFly;
        this.sentry = sentry;
    }
    ToastService.prototype.error = function (message) {
        this.sentry.breadcrumb(message, "navigation", "error");
        this.toastr.error(this.translateOnFly._(message));
    };
    ToastService.prototype.success = function (message) {
        this.sentry.breadcrumb(message, "navigation", "error");
        this.toastr.success(this.translateOnFly._(message));
    };
    ToastService.ngInjectableDef = i0.defineInjectable({ factory: function ToastService_Factory() { return new ToastService(i0.inject(i1.ToastrService), i0.inject(i2.TranslateOnFlyService), i0.inject(i3.SentryService)); }, token: ToastService, providedIn: "root" });
    return ToastService;
}());
export { ToastService };
