// tslint:disable
export const environment = {
  production: true,
  name: 'production',
  SENTRY_DSN: 'https://dbbb5bd126dc47f5a815ebeb713b8ee5@sentry.io/1810060',
  RELEASE: '0.0.4#af775fd',
  LANGUAGE: 'DE',
  API: 'api.hhotels.coara.xyz',
  PROTOCOL: 'https',
  URL_REGISTER: 'https://hotmiles.h-hotels.com/portal/user/register/',
  URL_FORGOT_PASSWORD: 'https://hotmiles.h-hotels.com/portal/user/forgotpw/',
  URL: 'https://bahnbonus.h-hotels.com',
};
