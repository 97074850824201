import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../providers/api/api.service";
import { ToastService } from "../../services/toast/toast.service";
import { getAssetPath } from "../../utils";
import { IPointsResult, IExchangePackageAvailableToUser } from "src/shared/api";
import { FormGroup, FormControl, AbstractControl, Validators } from "@angular/forms";
import { DeutscheBahnCardValidatorService } from "src/app/services/deutsche-bahn/deutsche-bahn-card-validator.service";
import { TranslateOnFlyService } from "src/app/services/translate-on-fly/translate-on-fly.service";

@Component({
  selector: "app-my-points",
  templateUrl: "./my-points.component.html",
  styleUrls: ["./my-points.component.scss"],
})
export class MyPointsComponent implements OnInit {
  public loadings: {
    getPoints: boolean;
    redeemPoints: boolean;
  } = {
    getPoints: true,
    redeemPoints: false,
  };
  public state: "showPoints" | "showForm" | "showSuccess";
  public myPoints: IPointsResult;
  public confirm: boolean;
  public modalTitle: string;
  public modalExplanation: string;
  public currentSelectedPackage: IExchangePackageAvailableToUser;
  public form: FormGroup;

  constructor(
    public t: TranslateOnFlyService,
    private api: ApiService,
    private toast: ToastService,
    private dbCardValidatorService: DeutscheBahnCardValidatorService,
  ) {
    this.form = new FormGroup({
      firstName: new FormControl("", [Validators.required]),
      lastName: new FormControl("", [Validators.required]),
      cardNumber: new FormControl("", [Validators.required, this.ValidateDeutscheBahnCard]),
      pack: new FormControl(null, [Validators.required]),
    });
  }

  public async ngOnInit(): Promise<void> {
    this.modalTitle = this.t._("confirmation");
    this.modalExplanation = "";
    this.getMyPoints();
  }

  public selectPack(pack: IExchangePackageAvailableToUser): void {
    if (pack.active === false) {
      return;
    }
    this.state = "showForm";
    this.currentSelectedPackage = pack;
    this.form.get("pack").setValue(pack);
  }

  public showConfirm(): void {
    if (this.currentSelectedPackage.active === false) {
      return;
    }
    this.modalExplanation = this.t._("modal_explanation", {
      a: this.currentSelectedPackage.hotmiles + "",
      b: this.currentSelectedPackage.dbPoints + "",
    });
    this.confirm = true;
  }

  public async redeemPoints(isConfirmed: boolean): Promise<void> {
    this.loadings.redeemPoints = true;
    this.confirm = false;
    if (isConfirmed === true) {
      try {
        if (this.form.valid) {
          const result = await this.api.redeemPoints(this.form.getRawValue());
          if (result.ok === false) {
            this.toast.error(result.error.message);
          } else {
            this.myPoints = result.response;
            this.state = "showSuccess";
          }
        }
      } catch (error) {
        this.getMyPoints();
        this.toast.error(error.error && error.error.error && error.error.error.message);
      } finally {
        this.loadings.redeemPoints = false;
      }
    } else {
      this.toast.error(this.t._("send_error"));
    }
  }

  public downloadCoupon(url: string): void {
    const win = window.open(getAssetPath(url), "_blank");
    win.focus();
  }

  public onSubmit(): void {
    // TODO: Use EventEmitter with form value
    console.log(this.form.value);
    if (this.form.valid === false) {
      return;
    }

    this.showConfirm();
  }

  public showError(formControlName: string): boolean {
    return this.form.controls[formControlName].dirty && !this.form.controls[formControlName].untouched;
  }

  public isDisabled(totalPoints: number, hotmiles: number): boolean {
    return hotmiles > totalPoints;
  }

  private async getMyPoints(): Promise<void> {
    try {
      this.loadings.getPoints = true;
      this.state = "showPoints";
      const res = await this.api.getPoints();
      if (res.ok === true) {
        this.myPoints = res.response;
      } else {
        this.toast.error(res.error.message);
      }
    } catch (error) {
      this.toast.error(error.error && error.error.error && error.error.error.message);
    } finally {
      this.loadings.getPoints = false;
    }
  }

  private ValidateDeutscheBahnCard = (control: AbstractControl): any => {
    if (this.dbCardValidatorService == null) {
      return null;
    }
    if (this.dbCardValidatorService.validateCardNumber(control.value) === false) {
      return { invalidCardNumber: true };
    }
    return null;
    // tslint:disable-next-line:semicolon
  };
}
