import { Router } from "@angular/router";
import { AuthService } from "src/app/providers/auth/auth.service";
import { ROUTES } from "src/app/constants";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../providers/auth/auth.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, auth) {
        this.router = router;
        this.auth = auth;
    }
    AuthGuard.prototype.canActivate = function () {
        var canActive = this.auth.isLogged();
        if (!canActive)
            this.router.navigate([ROUTES.LOGIN]);
        return canActive;
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.Router), i0.inject(i2.AuthService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
