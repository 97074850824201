import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";

import { ROUTES } from "../../constants";
import { ApiService } from "../../providers/api/api.service";
import { AuthService } from "../../providers/auth/auth.service";
import { ToastService } from "../../services/toast/toast.service";
import { environment } from "src/environments/environment";
import { TranslateOnFlyService } from "src/app/services/translate-on-fly/translate-on-fly.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public loading: boolean;
  public form: FormGroup;
  public registerUrl: string;
  public forgotPasswordUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private router: Router,
    private auth: AuthService,
    private toast: ToastService,
    public t: TranslateOnFlyService,
  ) {}

  public ngOnInit(): void {
    this.registerUrl = environment.URL_REGISTER;
    this.forgotPasswordUrl = environment.URL_FORGOT_PASSWORD;
    this.createForm();
  }

  public async sendCreddentials(): Promise<void> {
    this.loading = true;
    try {
      const res = await this.api.login(this.form.controls["email"].value, this.form.controls["password"].value);
      if (res.ok === true) {
        this.auth.saveToken(res.response);
        this.router.navigate([ROUTES.MY_POINTS]);
      } else {
        this.toast.error(res.error.message);
      }
    } catch (error) {
      this.toast.error(error.error && error.error.error && error.error.error.message);
    } finally {
      this.loading = false;
    }
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      email: ["", Validators.compose([Validators.required])],
      password: ["", Validators.compose([Validators.required])],
    });
  }
}
