import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "src/app/providers/auth/auth.service";
import { ROUTES } from "src/app/constants";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) {}
  public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const canActive = this.auth.isLogged();
    if (!canActive) this.router.navigate([ROUTES.LOGIN]);
    return canActive;
  }
}
