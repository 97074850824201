import { HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { ROUTES } from "../../constants";
import { AuthService } from "../auth/auth.service";
import * as i0 from "@angular/core";
import * as i1 from "../auth/auth.service";
import * as i2 from "@angular/router";
var InterceptorService = /** @class */ (function () {
    function InterceptorService(auth, router) {
        this.auth = auth;
        this.router = router;
    }
    InterceptorService.prototype.intercept = function (request, next) {
        var _this = this;
        request = request.clone({
            setHeaders: {
                Authorization: "Bearer " + this.auth.getToken(),
            },
        });
        return next.handle(request).pipe(tap(function (event) {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
            }
        }, function (err) {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    _this.auth.removeToken();
                    _this.router.navigate([ROUTES.LOGIN]);
                }
            }
        }));
    };
    InterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function InterceptorService_Factory() { return new InterceptorService(i0.inject(i1.AuthService), i0.inject(i2.Router)); }, token: InterceptorService, providedIn: "root" });
    return InterceptorService;
}());
export { InterceptorService };
