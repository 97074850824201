import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

import { TranslateOnFlyService } from "../translate-on-fly/translate-on-fly.service";
import { SentryService } from "../sentry/sentry.service";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor(private toastr: ToastrService, private translateOnFly: TranslateOnFlyService, private sentry: SentryService) {}

  public error(message: string): void {
    this.sentry.breadcrumb(message, "navigation", "error");
    this.toastr.error(this.translateOnFly._(message));
  }

  public success(message: string): void {
    this.sentry.breadcrumb(message, "navigation", "error");
    this.toastr.success(this.translateOnFly._(message));
  }
}
