import * as creditCardType from "credit-card-type";
import { CreditCardTypeInfo } from "credit-card-type";
import * as i0 from "@angular/core";
var DeutscheBahnCardValidatorService = /** @class */ (function () {
    function DeutscheBahnCardValidatorService() {
        creditCardType.addCard({
            niceType: "Deutsche Bahn",
            type: "deutsche-bahn",
            patterns: [70814],
            gaps: [4, 8, 12],
            lengths: [16],
            code: {
                name: "",
                size: 0,
            },
        });
        creditCardType.changeOrder("deutsche-bahn", 0);
    }
    DeutscheBahnCardValidatorService.prototype.validateCardNumber = function (cardnumber) {
        if (cardnumber == null) {
            return false;
        }
        cardnumber = cardnumber.replace(/\s/g, ""); // replace all white spaces
        if (cardnumber.trim().length !== 16) {
            return false;
        }
        var types = creditCardType(cardnumber);
        if (types.length === 0) {
            return false;
        }
        var creditCardTypeInfo = types[0];
        if (creditCardTypeInfo.niceType !== "Deutsche Bahn") {
            return false;
        }
        return this.luhnCheck(cardnumber);
    };
    DeutscheBahnCardValidatorService.prototype.luhnCheck = function (cardnumber) {
        if (/[^0-9-\s]+/.test(cardnumber))
            return false;
        var nCheck = 0, bEven = false;
        cardnumber = cardnumber.replace(/\D/g, "");
        for (var n = cardnumber.length - 1; n >= 0; n--) {
            var cDigit = cardnumber.charAt(n);
            var nDigit = parseInt(cDigit, 10);
            if (bEven && (nDigit *= 2) > 9)
                nDigit -= 9;
            nCheck += nDigit;
            bEven = !bEven;
        }
        return (nCheck % 10) === 0;
    };
    DeutscheBahnCardValidatorService.ngInjectableDef = i0.defineInjectable({ factory: function DeutscheBahnCardValidatorService_Factory() { return new DeutscheBahnCardValidatorService(); }, token: DeutscheBahnCardValidatorService, providedIn: "root" });
    return DeutscheBahnCardValidatorService;
}());
export { DeutscheBahnCardValidatorService };
