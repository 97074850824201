import { Injectable, ErrorHandler } from "@angular/core";
import * as Raven from "raven-js";

import { environment } from "../../../environments/environment";

Raven.config(environment.SENTRY_DSN, {
  environment: environment.name,
  release: environment.RELEASE,
}).install();

@Injectable({
  providedIn: "root",
})
export class SentryService implements ErrorHandler {
  public handleError(err: any): void {
    if (environment.production) {
      Raven.captureException(err);
    } else {
      console.error(err);
    }
  }

  public breadcrumb(
    message: string = "",
    type: "http" | "navigation" = "navigation",
    level: "log" | "error" = "log",
    data: object = {},
  ): void {
    if (environment.production) {
      Raven.captureBreadcrumb({
        message,
        level,
        type,
        data,
      });
    }
  }
}
