<app-confirmation-modal
  *ngIf="confirm"
  [title]="modalTitle"
  [explanation]="modalExplanation"
  (isConfirmed)="redeemPoints($event, myPoints.totalPoints)"
></app-confirmation-modal>
<content class="my-points">
  <article class="my-points__box-show-points" *ngIf="state === 'showPoints'">
    <span class="my-points__box-show-points-wrapper">
      <div class="my-points__box-show-points__header">
        <h1 class="my-points__box-show-points__header__title">
          {{ t._("total_hotmailes") }}
        </h1>
        <h2 class="my-points__box-show-points__header__points" *ngIf="!loadings.getPoints">
          {{ myPoints?.totalPoints }}
        </h2>
        <p class="my-points__box-show-points__header__subtitle">
          {{ t._("points_description") }}
        </p>
      </div>
      <app-spinner class="my-points__box-show-points__spinner" *ngIf="loadings.getPoints" [size]="'big'"></app-spinner>
      <table class="my-points__box-show-points__table">
        <thead>
          <tr>
            <th>{{ t._("hotmiles") }}</th>
            <th>{{ t._("db_points") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="my-points__box-show-points__table__body__row">
          <tr class="my-points__box-show-points__table__body__row" *ngFor="let exchangePackage of myPoints?.exchangePackages">
            <td class="my-points__box-show-points__table__body__row__column">{{ exchangePackage.hotmiles }}</td>
            <td class="my-points__box-show-points__table__body__row__column">{{ exchangePackage.dbPoints }}</td>
            <td class="my-points__box-show-points__table__body__row__column__button">
              <button
                (click)="selectPack(exchangePackage)"
                [disabled]="isDisabled(myPoints?.totalPoints, exchangePackage.hotmiles)"
                [class.--disabled]="isDisabled(myPoints?.totalPoints, exchangePackage.hotmiles)"
              >
                {{ t._("redeem") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </span>
  </article>
  <article *ngIf="state === 'showForm'" class="my-points__box-show-form">
    <span class="my-points__box-show-form-wrapper">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <span class="app-input">
          <label class="app-input__title">
            {{ t._("your_id") }}
          </label>
          <input class="app-input__element" formControlName="cardNumber" placeholder="{{ t._('type_id') }}" />
        </span>
        <span class="app-input__error">
          <span *ngIf="showError('cardNumber')">
            <p class="app-input__error__text" *ngIf="form.controls['cardNumber'].errors?.required">
              {{ t._("is_required") }}
            </p>
            <p
              class="app-input__error__text"
              *ngIf="!form.controls['cardNumber'].errors?.required && form.controls['cardNumber'].errors?.invalidCardNumber"
            >
              {{ t._("invalid_card") }}
            </p>
          </span>
        </span>
        <span class="app-input">
          <label class="app-input__title">
            {{ t._("first_name") }}
          </label>
          <input class="app-input__element" formControlName="firstName" placeholder="{{ t._('type_first_name') }}" />
        </span>
        <span class="app-input__error">
          <span *ngIf="showError('firstName')">
            <p class="app-input__error__text" *ngIf="form.controls['firstName'].errors?.required">
              {{ t._("is_required") }}
            </p>
          </span>
        </span>
        <span class="app-input">
          <label class="app-input__title">
            {{ t._("last_name") }}
          </label>
          <input class="app-input__element" formControlName="lastName" placeholder="{{ t._('type_last_name') }}" />
        </span>
        <span class="app-input__error">
          <span *ngIf="showError('lastName')">
            <p class="app-input__error__text" *ngIf="form.controls['lastName'].errors?.required">
              {{ t._("is_required") }}
            </p>
          </span>
        </span>
        <div class="my-points__box-show-form-wrapper__form__button-box">
          <app-button type="submit" [disabled]="form.pristine || form.invalid">
            <p class="input__text">
              {{ t._("redeem_hotmailes", { a: currentSelectedPackage.hotmiles }) }}
            </p>
          </app-button>
          <app-button [color]="'dark'" type="submit" (eventClick)="state = 'showPoints'">
            <p class="input__text">
              {{ t._("cancel") }}
            </p>
          </app-button>
        </div>
      </form>
    </span>
  </article>
  <article *ngIf="state === 'showSuccess'" class="my-points__box-show-success">
    <span class="my-points__box-show-success-wrapper">
      <span class="my-points__box-show-success-wrapper__header">
        <img class="my-points__box-show-success-wrapper__header__logo" src="../assets/images/logo.svg" />
        <span class="my-points__box-show-success-wrapper__header__points">
          <span class="my-points__box-show-success-wrapper__header__points__point"></span>
          <span class="my-points__box-show-success-wrapper__header__points__point"></span>
          <span class="my-points__box-show-success-wrapper__header__points__point"></span>
          <span class="my-points__box-show-success-wrapper__header__points__point"></span>
          <span class="my-points__box-show-success-wrapper__header__points__point"></span>
        </span>
        <img class="my-points__box-show-success-wrapper__header__db" src="../assets/images/bahn-bon-logo.png" />
      </span>
      <span class="my-points__box-show-success-wrapper__body">
        <p class="my-points__box-show-success-wrapper__body__title">{{ t._("already_send") }}</p>
        <p class="my-points__box-show-success-wrapper__body__text">
          {{ t._("process_message", { hotmiles: currentSelectedPackage.hotmiles, dbpoints: currentSelectedPackage.dbPoints }) }}
        </p>
        <br />
        <p class="my-points__box-show-points__table__body__row__column__button">
          <button class="--big" (click)="state = 'showPoints'">{{ t._("go_back") }}</button>
        </p>
      </span>
    </span>
  </article>
</content>
