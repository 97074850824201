import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { ToastrModule, ToastContainerModule, ToastNoAnimation, ToastNoAnimationModule } from "ngx-toastr";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SentryService } from "./services/sentry/sentry.service";
import { LoginComponent } from "./pages/login/login.component";
import { InputComponent } from "./components/atoms/input/input.component";
import { ButtonComponent } from "./components/atoms/button/button.component";
import { MyPointsComponent } from "./pages/my-points/my-points.component";
import { SpinnerComponent } from "./components/electrons/spinner/spinner.component";
import { InterceptorService } from "./providers/interceptor/interceptor.service";
import { ConfirmationModalComponent } from "./components/molecules/confirmation-modal/confirmation-modal.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InputComponent,
    ButtonComponent,
    MyPointsComponent,
    SpinnerComponent,
    ConfirmationModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastNoAnimationModule,
    ToastContainerModule,
    ToastrModule.forRoot({
      positionClass: "inline",
      toastComponent: ToastNoAnimation,
      preventDuplicates: true,
      autoDismiss: true,
      maxOpened: 1,
      closeButton: false,
      timeOut: 5000,
    }),
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
