import { ErrorHandler } from "@angular/core";
import * as Raven from "raven-js";
import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
Raven.config(environment.SENTRY_DSN, {
    environment: environment.name,
    release: environment.RELEASE,
}).install();
var SentryService = /** @class */ (function () {
    function SentryService() {
    }
    SentryService.prototype.handleError = function (err) {
        if (environment.production) {
            Raven.captureException(err);
        }
        else {
            console.error(err);
        }
    };
    SentryService.prototype.breadcrumb = function (message, type, level, data) {
        if (message === void 0) { message = ""; }
        if (type === void 0) { type = "navigation"; }
        if (level === void 0) { level = "log"; }
        if (data === void 0) { data = {}; }
        if (environment.production) {
            Raven.captureBreadcrumb({
                message: message,
                level: level,
                type: type,
                data: data,
            });
        }
    };
    SentryService.ngInjectableDef = i0.defineInjectable({ factory: function SentryService_Factory() { return new SentryService(); }, token: SentryService, providedIn: "root" });
    return SentryService;
}());
export { SentryService };
