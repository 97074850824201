<section class="login">
  <div class="login__box">
    <div class="login__box__header">
      <img class="login__box__header__logo1" src="../assets/hhotels_logo.png" />
      <h1 class="login__box__header__title">
        {{ t._("log_in") }}
      </h1>
    </div>
    <form class="login__box__form" (submit)="sendCreddentials()" [formGroup]="form" validate>
      <span class="login__box__form__input app-input">
        <label class="app-input__title">
          {{ t._("email") }}
        </label>
        <input
          class="app-input__element"
          [class.-has-error]="form.controls['email'].dirty && !form.controls['email'].untouched && form.controls['email'].errors"
          formControlName="email"
          placeholder="{{ t._('type_email') }}"
          type="email"
        />
        <span class="app-input__error">
          <span *ngIf="form.controls['email'].dirty && !form.controls['email'].untouched">
            <p class="app-input__error__text" *ngIf="form.controls['email'].errors?.email">
              {{ t._("invalid_email") }}
            </p>
            <p class="app-input__error__text" *ngIf="form.controls['email'].errors?.required">
              {{ t._("is_required") }}
            </p>
          </span>
        </span>
      </span>
      <span class="login__box__form__input app-input">
        <label class="app-input__title">
          {{ t._("password") }}
        </label>
        <input
          class="app-input__element"
          [class.-has-error]="form.controls['password'].dirty && !form.controls['password'].untouched && form.controls['password'].errors"
          formControlName="password"
          placeholder="{{ t._('type_password') }}"
          type="password"
        />
        <span class="app-input__error">
          <span *ngIf="form.controls['password'].dirty && !form.controls['password'].untouched">
            <p class="app-input__error__text" *ngIf="form.controls['password'].errors?.required">
              {{ t._("is_required") }}
            </p>
          </span>
        </span>
      </span>
      <app-button class="login__box__form__button" [loading]="loading" [type]="'submit'" [disabled]="form.pristine || form.invalid">
        <p class="input__text">
          {{ t._("log_in") }}
        </p>
      </app-button>
    </form>
    <a class="login__box__forgot-password" [href]="forgotPasswordUrl">
      {{ t._("forgot_password") }}
    </a>
    <div class="login__box__footer">
      <p class="login__box__footer__title">
        {{ t._("dont_have_account") }}
      </p>
      <a class="login__box__footer__link-register" [href]="registerUrl">
        {{ t._("register") }}
      </a>
    </div>
  </div>
</section>
