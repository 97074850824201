import * as i0 from "@angular/core";
var AuthService = /** @class */ (function () {
    function AuthService() {
    }
    AuthService.prototype.isLogged = function () {
        var token = this.getToken();
        if (token) {
            return true;
        }
        return false;
    };
    AuthService.prototype.getToken = function () {
        var token = localStorage.getItem("token");
        if (token) {
            return token;
        }
        return null;
    };
    AuthService.prototype.removeToken = function () {
        localStorage.clear();
    };
    AuthService.prototype.saveToken = function (token) {
        if (token) {
            localStorage.setItem("token", token);
        }
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
