import { EventEmitter, Component, Input, Output } from "@angular/core";

@Component({
  selector: "app-confirmation-modal",
  templateUrl: "./confirmation-modal.component.html",
  styleUrls: ["./confirmation-modal.component.scss"],
})
export class ConfirmationModalComponent {
  @Input() public show: boolean;
  @Input() public title: string;
  @Input() public subtitle?: string;
  @Input() public explanation?: string;
  @Output() public isConfirmed: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  public close(confirm: boolean): void {
    this.isConfirmed.emit(confirm);
  }
}
