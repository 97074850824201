import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  @Input() public disabled?: boolean;
  @Input() public color?: "primary" | "dark" | "light" = "primary";
  @Input() public loading?: boolean;
  @Input() public type?: "submit" | null;
  @Output() public eventClick: EventEmitter<void> = new EventEmitter();
  constructor() {
    this.type = null;
    this.loading = false;
    this.disabled = false;
  }

  public onClick(): void {
    this.eventClick.emit();
  }
}
