import { Injectable } from "@angular/core";
import * as creditCardType from "credit-card-type";
import { getTypeInfo, CreditCardTypeInfo } from "credit-card-type";
@Injectable({
  providedIn: "root",
})
export class DeutscheBahnCardValidatorService {
  constructor() {
    (<any>creditCardType).addCard({
      niceType: "Deutsche Bahn",
      type: "deutsche-bahn",
      patterns: [70814],
      gaps: [4, 8, 12],
      lengths: [16],
      code: {
        name: "",
        size: 0,
      },
    });
    (<any>creditCardType).changeOrder("deutsche-bahn", 0);
  }

  public validateCardNumber(cardnumber: string): boolean {
    if (cardnumber == null) {
      return false;
    }
    cardnumber = cardnumber.replace(/\s/g, ""); // replace all white spaces

    if (cardnumber.trim().length !== 16) {
      return false;
    }
    const types = creditCardType(cardnumber);
    if (types.length === 0) {
      return false;
    }

    const creditCardTypeInfo: CreditCardTypeInfo = types[0];
    if (creditCardTypeInfo.niceType !== "Deutsche Bahn") {
      return false;
    }

    return this.luhnCheck(cardnumber);
  }

  private luhnCheck(cardnumber: string): boolean {
    if (/[^0-9-\s]+/.test(cardnumber)) return false;

    let nCheck = 0, bEven = false;
    cardnumber = cardnumber.replace(/\D/g, "");
    for (let n = cardnumber.length - 1; n >= 0; n--) {
      const cDigit = cardnumber.charAt(n);
      let   nDigit = parseInt(cDigit, 10);
      if (bEven && (nDigit *= 2) > 9) nDigit -= 9;
      nCheck += nDigit;
      bEven = !bEven;
    }
    return (nCheck % 10) === 0;
  }
}
