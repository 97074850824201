var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ROUTES } from "../../constants";
import { ApiService } from "../../providers/api/api.service";
import { AuthService } from "../../providers/auth/auth.service";
import { ToastService } from "../../services/toast/toast.service";
import { environment } from "src/environments/environment";
import { TranslateOnFlyService } from "src/app/services/translate-on-fly/translate-on-fly.service";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, api, router, auth, toast, t) {
        this.formBuilder = formBuilder;
        this.api = api;
        this.router = router;
        this.auth = auth;
        this.toast = toast;
        this.t = t;
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.registerUrl = environment.URL_REGISTER;
        this.forgotPasswordUrl = environment.URL_FORGOT_PASSWORD;
        this.createForm();
    };
    LoginComponent.prototype.sendCreddentials = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.api.login(this.form.controls["email"].value, this.form.controls["password"].value)];
                    case 2:
                        res = _a.sent();
                        if (res.ok === true) {
                            this.auth.saveToken(res.response);
                            this.router.navigate([ROUTES.MY_POINTS]);
                        }
                        else {
                            this.toast.error(res.error.message);
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        this.toast.error(error_1.error && error_1.error.error && error_1.error.error.message);
                        return [3 /*break*/, 5];
                    case 4:
                        this.loading = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.createForm = function () {
        this.form = this.formBuilder.group({
            email: ["", Validators.compose([Validators.required])],
            password: ["", Validators.compose([Validators.required])],
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
