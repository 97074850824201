import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
var TranslateOnFlyService = /** @class */ (function () {
    function TranslateOnFlyService() {
        this.language = this.getDefaultLanguage();
        this.translations = require("../../../i18n/on-fly-translates/" + this.language + ".json");
    }
    TranslateOnFlyService.prototype._ = function (textToTranslate, variables) {
        if (variables === void 0) { variables = null; }
        var translate = textToTranslate ? this.translations[textToTranslate] || textToTranslate : "";
        if (variables && translate !== "") {
            translate = Object.keys(variables).reduce(function (acc, key) { return acc.replace(new RegExp("<%= " + key + " %>", "g"), variables[key]); }, translate);
        }
        return translate;
    };
    TranslateOnFlyService.prototype.getDefaultLanguage = function () {
        var browserLang = (navigator.language || "").split("-")[0].toLocaleLowerCase() || "";
        if (browserLang === "en" || browserLang === "de")
            return browserLang;
        return (environment.LANGUAGE && environment.LANGUAGE.toLocaleLowerCase()) || "en";
    };
    TranslateOnFlyService.ngInjectableDef = i0.defineInjectable({ factory: function TranslateOnFlyService_Factory() { return new TranslateOnFlyService(); }, token: TranslateOnFlyService, providedIn: "root" });
    return TranslateOnFlyService;
}());
export { TranslateOnFlyService };
