export const ROUTES = {
  HOME: "",
  LOGIN: "login",
  MY_POINTS: "my-points",
};

export const ENDPOINTS = {
  LOGIN: "authenticate",
  POINTS: "api/p4m/points",
  REDEEM_POINTS: "api/p4m/redeem-points",
  HISTORY: "history",
};
